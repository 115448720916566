<ion-card>
    <ion-card-header>
    <ion-card-title>{{ appNome }}</ion-card-title>
    <ion-card-subtitle>Versão: {{ versao }}</ion-card-subtitle>
    <p class="linha autor">Autor: {{ autor }}</p>
    <p class="linha">Ambiente: {{ ambiente }}</p>
    <p class="linha" *ngIf="staging">Versão STA: {{ versaoHomolg }}</p>
    </ion-card-header>
    <ion-card-content>
        {{ descricao }}
    </ion-card-content>
</ion-card>
