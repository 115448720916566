<ion-card class="ion-margin-top">
    <ion-card-header>
      <ion-card-title>{{ titulo }}</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <ion-list lines="none">
        <ion-radio-group [(ngModel)]="tipoPesquisa" (ionChange)="radioGroupChange($event)">
          <ion-item>
            <ion-input 
              type="tel" 
              inputmode="numeric"
              autocomplete="off"
              placeholder="por etiqueta" 
              [maskito]="etiquetaMask"
              [maskitoElement]="maskPredicate"
              [(ngModel)]="etiqueta" 
              [disabled]="tipoPesquisa=='numeroSerie'">
            </ion-input>

            <ion-button color="light" slot="end" size="small" class="ion-align-self-center" (click)="onPesquisaEtiquetaClick()" [disabled]="tipoPesquisa!='etiqueta'">
              <ion-icon slot="icon-only" name="qr-code" color="primary"></ion-icon>
            </ion-button> 
  
            <ion-radio slot="start" value="etiqueta" mode="ios" ></ion-radio>
          </ion-item>
      
          <ion-item>
            <ion-input type="text" placeholder="por nº série" [(ngModel)]="numeroSerie" [disabled]="tipoPesquisa=='etiqueta'"></ion-input>

            <ion-button color="light" slot="end" size="small" class="ion-align-self-center" (click)="onPesquisaNumeroSerieClick()" [disabled]="tipoPesquisa=='etiqueta'">
              <ion-icon slot="icon-only" name="barcode-sharp" color="primary"></ion-icon>
            </ion-button>
  
            <ion-radio slot="start" value="numeroSerie" mode="ios" ></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>

      <ion-footer>
        <ion-row>
          <ion-col>
            <div class="ion-text-center">
              <ion-button (click)="onPesquisarClick()" [color]="cor" size="small" [disabled]="!etiqueta && !numeroSerie">
                <ion-icon slot="start" name="search-sharp"></ion-icon>
                Pesquisar
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-footer>
  </ion-card-content>
  </ion-card>

  <ion-progress-bar [type]="'indeterminate'" *ngIf="showLoader"></ion-progress-bar>