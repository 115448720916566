<form padding [formGroup]="formulario" (ngSubmit)="onSubmit()" novalidate>
  <ion-item>
    <!-- <ion-label position="stacked">Usuário</ion-label> -->
    <ion-input 
      label="Usuário"
      labelPlacement="stacked"
      formControlName="usuario"
      type="text">
    </ion-input>
  </ion-item>

  <ion-item>
    <!-- <ion-label position="stacked">Senha</ion-label> -->
    <ion-input 
      label="Senha"
      labelPlacement="stacked"
      formControlName="senha" 
      [type]="showPassword ? 'text' : 'password'"
      enterkeyhint="go"
    >
    </ion-input>
    <ion-icon 
      slot="end" 
      class="ion-align-self-end"
      [name]="passwordToggleIcon" 
      (click)="togglePassword()">
    </ion-icon>
  </ion-item>

  <ion-button expand="block" class="ion-margin-top" type="submit" [color]="cor">
    <ion-icon slot="start" name="person"></ion-icon>
    Logar
  </ion-button>
</form>