<ion-header>
    <ion-toolbar [color]="toolBarColor">
      <ion-buttons slot="start">
        <ion-back-button [defaultHref]="getBackHref()" *ngIf="showBackButton()" ></ion-back-button>
        <ion-button (click)="fechar()" *ngIf="showBackButtonComCancelamento()">
          <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
        </ion-button>
        <ion-menu-button *ngIf="showMenu()"></ion-menu-button>
      </ion-buttons>
      <ion-title>
        {{ titulo }}
      </ion-title>
      <ion-buttons slot="end" *ngIf="showHomeButton()">
        <ion-button (click)="onGoHome()">
          <ion-icon slot="icon-only" name="home-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
