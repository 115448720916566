import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicSelectableCloseButtonTemplateDirective, IonicSelectableComponent } from 'ionic-selectable';
import { MaskitoModule } from '@maskito/angular';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NovoAparelhoInfoComponent } from './novo-aparelho-info.component';

@NgModule({
  declarations: [
    NovoAparelhoInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MaskitoModule,
    IonicSelectableComponent,
    IonicSelectableCloseButtonTemplateDirective,
  ],
  exports: [
    NovoAparelhoInfoComponent
  ]
})
export class NovoAparelhoInfoModule { }
