import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (!value) return ''

    return super.transform(value, 'dd/MM/yyyy HH:mm', 'ptBr');
  }

}