import { Component, Input, OnInit } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion'; 

@Component({
  selector: 'util-sobre',
  templateUrl: './util-sobre.component.html',
  styleUrls: ['./util-sobre.component.scss']
})
export class UtilSobreComponent implements OnInit {

  @Input() appNome: string;
  @Input() descricao: string;
  @Input() versao: string;
  @Input() versaoHomolg: string;
  @Input() ambiente: string;
  @Input() autor: string;

  private _staging: boolean;
  @Input() 
  get staging(): boolean {
    return this._staging;
  };
set staging(value:boolean) {
  this._staging = coerceBooleanProperty(value);
}

  
  constructor() {}

  ngOnInit(): void {
  }

}
