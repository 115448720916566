import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private _isActive: boolean;

  constructor() { 
    this._isActive = false;
  }

  setActive() {
    this._isActive = true;
  }

  setInactive() {
    this._isActive = false;
  }

  isActive() {
    return this._isActive;
  }
}
