
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, InjectionToken, ModuleWithProviders } from '@angular/core';

import { CisApisConfig } from './cis-apis.config';
import { HttpConfigInterceptor } from './http-config.interceptor';
import { CIS_APIS_CONFIG } from './cis-apis.config.token';
import { PesquisaAparelhoComponent } from './components/pesquisa-aparelho/pesquisa-aparelho.component';
//import { SimpleMaskModule } from 'ngx-ion-simple-mask';
import { ListaAparelhosPesquisadosComponent } from './components/lista-aparelhos-pesquisados/lista-aparelhos-pesquisados.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { MaskitoModule } from '@maskito/angular';

export const LibConfigService = new InjectionToken<CisApisConfig>('CisApiConfig');

@NgModule({
  declarations: [PesquisaAparelhoComponent, ListaAparelhosPesquisadosComponent, SortByPipe],
  imports: [ 
    CommonModule,
    FormsModule,
    IonicModule,
    HttpClientModule,
    MaskitoModule
    //SimpleMaskModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
  ],
  exports: [PesquisaAparelhoComponent, ListaAparelhosPesquisadosComponent,SortByPipe]
})
export class CisApisModule {

  static forRoot(cisApisConfig: CisApisConfig): ModuleWithProviders<CisApisModule>{
    return {
      ngModule: CisApisModule,
      providers: [
        {
          provide: CIS_APIS_CONFIG,
          useValue: cisApisConfig
        }
      ]
    }
  }
 }
