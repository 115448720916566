import { NgModule, ModuleWithProviders } from '@angular/core';

import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { UtilErrorMsgModule } from './components/util-error-msg/util-error-msg.module';
import { UtilHeaderModule } from './components/util-header/util-header.module';
import { UtilNetworkLabelModule } from './components/util-network-label/util-network-label.module';
import { UtilsConfig } from './utils.config';
import { UTILS_CONFIG } from './utils.config.token';
import { IonicStorageModule } from '@ionic/storage-angular';
import { UtilSobreModule } from './components/util-sobre/util-sobre.module';
import { IonicModule } from '@ionic/angular';
import { UtilAparelhoListaModule } from './components/util-aparelho-lista/util-aparelho-lista.module';

@NgModule({
  declarations: [
    DateTimeFormatPipe,
  ],
  imports: [
    IonicModule,
    UtilHeaderModule,
    UtilErrorMsgModule,
    UtilNetworkLabelModule,
    UtilSobreModule,
    UtilAparelhoListaModule,
    IonicStorageModule.forRoot()
  ],
  exports: [
    DateTimeFormatPipe,
  ]
})
export class UtilsModule {

  static forRoot(utilsConfig: UtilsConfig): ModuleWithProviders<UtilsModule>{
    return {
      ngModule: UtilsModule,
      providers: [
        {
          provide: UTILS_CONFIG,
          useValue: utilsConfig
        }
      ]
    }
  }
 }