import { AparelhoTipo } from './aparelho-tipo';
import { IAparelho } from '../interfaces/i-aparelho';
import { Material } from './material';
import { Categoria } from './categoria';
import { AparelhoSubtipo } from './aparelho-subtipo';

export class Aparelho implements IAparelho  {
    Id: number;
    Etiqueta: string;
    Etiquetas?: string[];
    CodigoBarras?: string;
    NumeroSerie?: string;
    Material?: Material;
    Categoria?: Categoria;
    Tipo?: AparelhoTipo;
    Subtipo?: AparelhoSubtipo;
    Localizacao?: string;
    Etiqueta_Anterior?: string;
    BaixaAtivo?: Date;

    NumeroSerieNovo?: string;
    EtiquetaNova?: string;
    EstoqueId?: number;
    PessoaId?: number;

    constructor(){
        this.Tipo = new AparelhoTipo();
        this.Material = new Material();
    }
}
