<util-util-header 
  titulo="Nova Localização" 
  mostrarMenu="false" 
  mostrarBotaoVoltar="false"></util-util-header>

<ion-content class="ion-padding">
  <ion-item>
    <ion-label position="stacked">Informe a nova localização</ion-label>
    <ion-textarea autoGrow="true" rows="2" [(ngModel)]="novaLocalizacao"></ion-textarea>
  </ion-item>

  <ion-footer>
    <ion-row class="ion-justify-content-center">
      <ion-col size="6">
        <ion-button color="danger" size="small" (click)="cancelar()" expand="block">
          <ion-ripple-effect type="unbounded"></ion-ripple-effect>
          <ion-icon slot="start" name="close"></ion-icon>
          Cancelar
        </ion-button>
      </ion-col>
      <ion-col size="6">
          <ion-button color="primary" size="small" (click)="closeModal()" expand="block" class="ion-margin-end">
          <ion-icon name="save"></ion-icon>
          <ion-ripple-effect type="bounded"></ion-ripple-effect>
          Trocar
        </ion-button>
    </ion-col>
    </ion-row>
  </ion-footer>
</ion-content>
