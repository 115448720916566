import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CisApisModule } from '../../../../projects/libs/cis-apis/src/lib/cis-apis.module';
import { HttpConfigInterceptor } from './../../../libs/cis-apis/src/lib/http-config.interceptor';
import { CisAuthModule } from '../../../libs/cis-auth/src/lib/cis-auth.module';
import { CisLocalDbModule } from './../../../libs/cis-local-db/src/lib/cis-local-db.module';

import { environment } from '@env/environment';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { ServiceWorkerModule } from '@angular/service-worker';
import { NgCalendarModule } from 'ionic7-calendar';
//import { UtilsModule } from 'utils';
//import { UtilsModule } from '../../../../dist/utils/lib/utils';
//import { UtilsModule } from 'utils';
//import { UtilsModule } from './../../../../dist/utils/lib/utils.module';
import { UtilsModule } from './../../../libs/utils/src/lib/utils.module';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            hardwareBackButton: true,
            innerHTMLTemplatesEnabled: true,
        }),
        // IonicStorageModule.forRoot(),
        AppRoutingModule,
        CisAuthModule.forRoot({
            aplicativo: environment.aplicativo,
            apiUrl: environment.apiRoot
        }),
        CisApisModule.forRoot({
            aplicativo: environment.aplicativo,
            apiUrl: environment.apiRoot
        }),
        CisLocalDbModule.forRoot({
            aplicativo: environment.aplicativo
        }),
        UtilsModule.forRoot({
            chaveRequestOffline: environment.chaveRequestOffline,
            chaveRequestErroOffline: environment.chaveRequestErroOffline
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.hasServiceWork }),
        NgCalendarModule,
        ScrollingModule,
        //ComponentsModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
