import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CIS_APIS_CONFIG } from '../cis-apis.config.token';
import { CisApisConfig } from '../cis-apis.config';
import { Observable, of } from 'rxjs';
import { IAparelho } from '../interfaces/i-aparelho';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AparelhoApiService {
  apiUrl: string;

  constructor(
    @Inject(CIS_APIS_CONFIG) private cisApisConfig: CisApisConfig,
    private http: HttpClient
  ) { 

    this.apiUrl = this.cisApisConfig.apiUrl;
  }

  listar() : Observable<IAparelho[]> {

    return this.http.get<IAparelho[]>(`${this.apiUrl}/aparelhos`)
     .pipe(
       tap(tipos => console.log('fetched aparelhos')),//,
       catchError(this.handleError('AparelhoApiService.listar', []))
     );
   }

   listarPorEtiquetas(etiquetas: string) : Observable<IAparelho[]> {

    const params = new HttpParams({
      fromString: `etiquetas=${etiquetas}`
    });

    return this.http.get<IAparelho[]>(`${this.apiUrl}/aparelhos/poretiquetas`, {params: params})
     .pipe(
       //tap(tipos => console.log('fetched aparelhos')),//,
       catchError(this.handleError('AparelhoApiService.listarPorEtiquetas', []))
     );
   }
 
   contar() : Observable<number> {
 
     return this.http.get<any>(`${this.apiUrl}/aparelhos/contar`)
     .pipe(
       tap(vaule => {
         console.log('aparelhos contados')
       }),
       catchError(this.handleError('AparelhoService.contar', []))
     )
   }

   buscar(tipo: string, valor:string){

    const params = new HttpParams({
      fromString: `tipo=${tipo}&valor=${valor}`
    });
    return this.http.get<any>(`${this.apiUrl}/aparelhos/busca`, {params: params})
    .pipe(
      tap(tipos => console.log('busca efetuada')),//,
      catchError(this.handleError('AparelhoApiService.buscar', []))
    );
   }
 
   private handleError<T> (operation = 'operation', result?: T) {
     return (error: any): Observable<T> => {
   
       // TODO: send the error to remote logging infrastructure
       //console.error(error); // log to console instead
       console.log(`erro em ${operation}`);
       console.log(error);
       // Let the app keep running by returning an empty result.
       return of(result as T);
     };
   }
}
