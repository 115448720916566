import { IVeiculo } from "../interfaces/i-veiculo";

export class Veiculo implements IVeiculo {
    Id: number;
    Nome: string;
    Placa: string;
    UltimaQuilometragem?: number;
    TipoEvento?: string;

    constructor(id?: number, nome?: string, placa?: string) {
        this.Id = id;
        this.Nome = nome;
        this.Placa = placa;
    }

    get NomeCompleto() : string | null {
        return (this.Placa && this.Nome) ? this.Placa + ' - ' + this.Nome : '';
    }
}
