import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilNetworkLabelComponent } from './util-network-label.component';

@NgModule({
  declarations: [UtilNetworkLabelComponent],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [UtilNetworkLabelComponent]
})
export class UtilNetworkLabelModule { }
