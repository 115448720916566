import { Aplicativo } from './models/aplicativo';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { CIS_AUTH_CONFIG } from './cis-auth.config.token';
import { CisAuthConfig } from './cis-auth.config';
import { Usuario } from './models/usuario';
import { Router } from '@angular/router';

interface IAuthResponseData {
  access_token: string;
  expires_in: number
}

@Injectable({
  providedIn: 'root'
})
export class CisAuthService {
  apiUrl = this.cisAuthConfig.apiUrl;
  aplicativo = this.cisAuthConfig.aplicativo;

  user = new BehaviorSubject<Usuario>(null);
  private tokenExpirationTimer: any;

  constructor(
    @Inject(CIS_AUTH_CONFIG) private cisAuthConfig: CisAuthConfig,
    private http: HttpClient,
    private router: Router
  ) { }

  getToken(): string {

    const chave = JSON.parse( localStorage.getItem(this.aplicativo) );
    return chave.tokenAcesso;
  }

  getUsuario(): Usuario {

    const chave = JSON.parse( localStorage.getItem(this.aplicativo) );
    return chave?.usuario;
  }

  getUsuarioObservable(): Observable<any> {

    const chave = JSON.parse( localStorage.getItem(this.aplicativo) );

    return new Observable(subscriber => {
      subscriber.next(chave?.usuario);
    })
    //return chave?.usuario;
  }

  getAplicativo() {

    return JSON.parse( localStorage.getItem(this.aplicativo) );
  }

  logar(usuario_nome: string, senha: string) : Observable<any> {

    const url =  `${this.apiUrl}/token/security`; //`${environment.apiUrl}/token/security`;
    const data = `grant_type=password&username=${usuario_nome}&password=${senha}`;
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    
    return this.http.post<IAuthResponseData>(url, data, httpOptions)
      .pipe(
        tap(res => {
          // guarda o token para obter o usuário do banco
          const _aplicativo: Aplicativo = new Aplicativo();
          _aplicativo.tokenAcesso = res.access_token;
          localStorage.setItem(this.aplicativo, JSON.stringify(_aplicativo));
        }),
        switchMap(respData => {
          return this.usuarioLogadoObter(+respData.expires_in);
        })
        //,catchError(this.handleError('loginApiService.logar()', [])),
        //finalize(() => console.log("first finalize() block executed")),
      );
  }

  autoLogin() {

    const _appData: Aplicativo = this.getAplicativo();

    if (!_appData) {
      return;
    }

    if (this.localTokenIsValid(_appData.tokenExpirationDate)) {
      // console.log('aapData: ' , _appData);
      this.user.next(_appData.usuario);
      const expirationDate = new Date(_appData.tokenExpirationDate).getTime() - new Date().getTime();
      this.autoLogout(expirationDate);
    }
  }

  usuarioLogadoObter(expiresIn: number) : Observable<any>{

    const url =  `${this.apiUrl}/usuarios`;

    return this.http.get<any>(url) 
      .pipe(
        /* tap(vaule => {
        console.log('fetched usuário');
      }), */ 
      map(res => { 

        let _usuario: Usuario = res;
            
        this.handleAuthentication(_usuario, expiresIn);
      }),
      catchError(this.handleError('loginApiService.usuarioLogadoObter()', []))
    );
  }

  logout() {
    this.user.next(null);
    this.router.navigate(['/login']);
    localStorage.clear();

    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration); // 2000);
  }

  private handleAuthentication(usuario: Usuario, expiresIn: number) {

    // cria um expirationDate baseado no getTime() do javascript
    // vezes 1000 (para converter em miliseconds, pois expiresIn está em segundos)
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);

    const _aplicativo : Aplicativo = this.getAplicativo();
    _aplicativo.usuario = usuario;
    _aplicativo.tokenExpirationDate = expirationDate;

    //const user = new User(email, userId, token, expirationDate);
    this.user.next(usuario);
    this.autoLogout(expiresIn * 1000);
    localStorage.setItem(this.aplicativo, JSON.stringify(_aplicativo));
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      //console.log(error); // log to console instead
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private localTokenIsValid(tokenExpirationDate: Date): boolean {

    if (!tokenExpirationDate || new Date() > tokenExpirationDate) {
      return false;
    }
    return true;
  }
}
