import { Injectable } from '@angular/core';
//import { NetworkStatus, PluginListenerHandle, Plugins } from '@capacitor/core';
import { Network, ConnectionStatus } from '@capacitor/network';
import { PluginListenerHandle} from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';

//const { Network } = Plugins;
export enum eConnectionStatus {
  Online,
  Offline
}

@Injectable({
  providedIn: 'root'
})
export class UtilNetworkService {

  networkStatus: ConnectionStatus; // NetworkStatus;
  networkListener: PluginListenerHandle;
  private status: BehaviorSubject<eConnectionStatus> = new BehaviorSubject(eConnectionStatus.Offline);

  constructor(
    private plt: Platform
  ) { 
    this.plt.ready().then(() => {
      this.startListenNetwork();

      this.getStatus().then(() => {
        this.status.next(this.networkStatus.connected ? eConnectionStatus.Online : eConnectionStatus.Offline);
      });
    });
  }

  startListenNetwork() {
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      this.networkStatus = status;

      if (!status.connected) {
        // console.log('sem conexão');
        this.updateNetworkStatus(eConnectionStatus.Offline);
      } else {
        // console.log('com conexão');
        this.updateNetworkStatus(eConnectionStatus.Online);
      }
    });
  }

  private async updateNetworkStatus(status: eConnectionStatus) {
    this.status.next(status);

    const connection = status === eConnectionStatus.Offline ? 'Offline' : 'Online';
    console.log(`You are now ${connection}`);
    /* const toast = this.toastController.create({
      message: `You are now ${connection}`,
      duration: 3000,
      position: 'bottom'
    });
    // tslint:disable-next-line: no-shadowed-variable
    toast.then(toast => toast.present()); */
  }

   async getStatus() {
    try {
      this.networkStatus = await Network.getStatus();

      if (this.networkStatus.connected) {
        this.status.next(eConnectionStatus.Online);
      } else {
        this.status.next(eConnectionStatus.Offline);
      }
    } catch (error) {
      console.log('Erro de conexão' + error);
    }
  }

  public onNetworkChange(): Observable<eConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): eConnectionStatus {
    return this.status.getValue();
  }
  
  stopListenNetwork() {
    if (this.networkListener) {
      this.networkListener.remove();
    }
  }
}
