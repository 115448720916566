import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {

  constructor(
    public alertController: AlertController
  ) { }

  async presentAlert(
    header: string,
    subHeader: string,
    message: string) {

    const option: AlertOptions = {
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['OK']
    };

    const alert = await this.alertController.create( option );

    await alert.present();
  }
}
