import { NovaLocalizacaoModalPage } from './../modals/nova-localizacao-modal/nova-localizacao-modal.page';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreloadIonicComponentsService {

  constructor(
    private alertCtrl: AlertController,
    private modalController: ModalController,
    private toastController: ToastController
  ) { 
    alertCtrl.create().then(a => a.dismiss());
    modalController.create({component: NovaLocalizacaoModalPage}).then(m => m.dismiss());
    toastController.create().then(t => t.dismiss());
  }
}
