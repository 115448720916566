import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'util-util-header',
  templateUrl: './util-header.component.html',
  styleUrls: ['./util-header.component.scss'],
})
export class UtilHeaderComponent implements OnInit {

  @Input() public titulo: string;
  @Input() public cor: string;
  @Input() public mostrarMenu: string;
  @Input() public mostrarBotaoVoltar: string;
  @Input() public temCancelamento: string;
  @Input() public backHref: string;
  @Input() public mostrarBotaoHome: string;

  @Output() cancelamento: EventEmitter<string> = new EventEmitter<string>();
  @Output() goHome: EventEmitter<string> = new EventEmitter<string>();
  
  toolBarColor: string = "primary";

  //photo;

  constructor() { }

  ngOnInit(): void {
    this.toolBarColor = this.cor === undefined ? "primary" : this.cor;
    //console.log(this.backHref);
    //this.photo = "./../assets/eu.jpeg";
  }
  
  showMenu() {
    return this.mostrarMenu === "true" ? true : false;
  }

  showBackButton() {
    return this.mostrarBotaoVoltar === "true" ? true : false;
  }

  showBackButtonComCancelamento() {
    //debugger
    return this.temCancelamento === "true" ? true : false;
  } 

  getBackHref() {
    //console.log(this.backHref);
    return this.backHref;
  }

  showHomeButton() {
    return this.mostrarBotaoHome === "true" ? true : false;
  }

  onGoHome() {
    this.goHome.emit();
  }

  fechar() {
  
    this.cancelamento.emit();
  }
}
