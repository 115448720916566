import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  transform(items: any[], direction = '', column: string = '', type: string = 'string'): any[] {

    if (!items || direction === '' || !direction) { return items; } // no array

    if (items.length <= 1) { return items; } // array with only one item

    if (!column || column === '') {
      if (direction === 'asc') { return items.sort() }
      else { return items.sort().reverse(); }
    } // sort 1d array

    let sortedItems=[];
    sortedItems=direction==="asc" ? this.sortAscending(items,column,type): this.sortDescending(items,column,type)
   
    return sortedItems;
  }

  sortAscending(items, column, type) {

    return [...items.sort(function (a: any, b: any) {

      if (type === 'string') {
        if (a[column].toUpperCase() < b[column].toUpperCase()) return -1;
      }
      else {
        return a[column] - b[column];
      }
    })]
  }

  sortDescending(items, column, type) {
    return [...items.sort(function (a: any, b: any) {
      if (type === 'string') {
        if (a[column].toUpperCase() > b[column].toUpperCase()) return -1;
      }
      else {
        return b[column] - a[column];
      }
    })]
  }
}
