import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location, PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { Platform, AlertController, ModalController, IonRouterOutlet } from '@ionic/angular';

import { Subscription } from 'rxjs';
import { CisAuthService } from '../../../libs/cis-auth/src/lib/cis-auth.service';
import { PreloadIonicComponentsService } from './shared/services/preload-ionic-components.service';

import { UtilApplicationService } from './../../../libs/utils/src/lib/services/util-application.service';
import { UtilOfflineManagerService } from './../../../libs/utils/src/lib/services/util-offline-manager.service';
import { UtilNetworkService, eConnectionStatus } from './../../../libs/utils/src/lib/services/util-network.service';

import { FileSharer } from '@byteowls/capacitor-filesharer';
//import { Plugins, registerWebPlugin } from '@capacitor/core';
import { App } from '@capacitor/app';
import { ModalService } from './shared/services/modal.service';

//import { UtilApplicationService, UtilNetworkService, eConnectionStatus,  } from 'utils';

// import { BackButtonEvent } from '@ionic/core';

//const { App } =  Plugins;
const routerEl = document.querySelector('ion-router');

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  public selectedIndex = 0;
  public selectedSubItem;
  public appPages = [
    {
      title: 'Atendimento',
      children: [
        {
          title: 'Iniciar',
          url: '/atividades',
          icon: 'construct',
        },
        {
          title: 'Atividade Recuperação',
          url: '/atividades/recuperacao',
          icon: 'build'
        },
        {
          title: 'Meus PMOCs',
          url: '/pmocs',
          icon: 'contract'
        },
      ],
      open: false
    },
    {
      title: 'Atividades',
      children: [
        {
          title: 'Filtrar',
          url: '/atividades/filter',
          icon: 'funnel'
        },
        {
          title: 'Iniciadas',
          url: '/atividades/list/iniciadas',
          icon: 'create'
        },
/*         {
          title: 'Relatório atendimento',
          url: '/atividades/relatorio',
          icon: 'newspaper'
        } */
      ],
      open: false
    },
    {
      title: 'Aparelhos',
      children: [
        {
          title: 'Pré-cadastro devolução',
          url: '/aparelhos',
          icon: 'create'
        },
        {
          title: 'Devoluções p/ aceite',
          url: '/aparelhos/conferidos',
          icon: 'checkmark-done'
        }
      ]
    },
    {
      title: 'Consultas',
      children: [
        {
          title: 'Aparelhos',
          url: '/consultar-aparelho',
          icon: 'search'
        },
      ],
      open: false
    },
    {
      title: 'Dados',
      children: [
        {
          title: 'Carregar',
          url: '/dados',
          icon: 'cloud-upload'
        },
        {
          title: 'Sincronização',
          url: '/dados/descarregar',
          icon: 'cloud-download'
        },
      ],
      open: false
    },
    {
      title: 'Relatórios',
      children: [
        {
          title: 'Geração',
          url: '/relatorios',
          icon: 'print'
        }
      ],
      open: false
    },
    {
      title: 'Sobre',
      url: '/sobre',
      icon: 'settings',
      open: false
    },
    {
      title: 'Sair',
      url: '',
      icon: 'log-out',
      open: false
    }
  ];

  usuario = null;
  private userSub: Subscription;
  
  currentRoute: string;

  constructor(
    private platform: Platform,
    private authService: CisAuthService,
    private router: Router,
    private preloadIonicComponentService: PreloadIonicComponentsService,
    private networkService: UtilNetworkService,
    private offlineManager: UtilOfflineManagerService,
    private application: UtilApplicationService,
    private _location: Location,
    private alertCtrl: AlertController,
    private location: PlatformLocation,
    private modalCtrl: ModalController,
    private modalService: ModalService
  ) {
    this.initializeApp();

    this.location.onPopState(async () => {
      const modal = await this.modalCtrl.getTop();
      if (modal) {
        modal.dismiss();
      }
    });

    //this.router.getCurrentNavigation
  }

  initializeApp() {

    this.platform.ready().then(() => {

      this.platform.backButton.subscribeWithPriority(10, () => {
        if (!this.routerOutlet.canGoBack()) {
          this.showExitConfirm();
        }
        else {
          this._location.back();
        }
      });

      this.networkService.onNetworkChange().subscribe((status: eConnectionStatus) => {
          if (status == eConnectionStatus.Online) {
            if (this.usuario) {
              this.offlineManager.checkForEvents().subscribe();
              // .subscribe(data => 
              //   this.offlineManager.storeFailedRequest(data)
              // );
            }
          }
        });

        //registerWebPlugin(FileSharer);

      });
      
    App.addListener('appStateChange', ({ isActive }) => {

      //console.log('App stage changed. Is active ?', isActive)
      //console.log('this.router.url: ', this.router.url);
      //console.log('this.modalService.isActive(): ', this.modalService.isActive())
        if (this.router.url == '/relatorios/dados' && this.modalService.isActive()) {
          document.documentElement.requestFullscreen();
          window.screen.orientation.lock("landscape-primary");
        }
      });

      /*  App.addListener('backButton', (data: AppUrlOpen) => {
        console.log('data', data);
        console.log('backbutton');
        //this.showExitConfirm();
      }); */

/*       this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
        console.log('Back press handler!');
        if (this._location.isCurrentPathEqualTo('/atividades')) {
  
          // Show Exit Alert!
          console.log('Show Exit Alert!');
          this.showExitConfirm();
          processNextHandler();
        } else {
  
          // Navigate to back page
          console.log('Navigate to back page');
          this._location.back();
  
        }
  
      });
  
      this.platform.backButton.subscribeWithPriority(5, () => {
        console.log('Handler called to force close!');
        this.alertCtrl.getTop().then(r => {
          if (r) {
            navigator['app'].exitApp();
          }
        }).catch(e => {
          console.log(e);
        })
      });
 */  }

  ngOnInit() {
   
    if (!this.platform.is('hybrid')) {
      this.application.registerForUpdates();
    }

    this.authService.autoLogin();
    
    this.userSub = this.authService.user.subscribe(user => {
      this.usuario = user;

      if (this.usuario) {
        this.offlineManager.checkForEvents().subscribe();
      }

    });
  }

  onLogout() {
      this.authService.logout();
      this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.networkService.stopListenNetwork();
  }

 showExitConfirm() {
    this.alertCtrl.create({
      header: 'App termination',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          //navigator['app'].exitApp();
          App.exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  } 
}