export const environment = {
    production: false,
    hasServiceWork: true,
    staging: true,
    aplicativo: 'App-Atividade',
    localAtividadeDbName: 'CisLocalAtividadeDB',
    chaveRequestOffline: 'storedreq',
    chaveRequestErroOffline: 'stroredfailedreq',  
    apiRoot: 'https://colortel-cts-app-sta.azurewebsites.net/api/v2/mobile',
    urlImages: './../../../assets/icons/',
    logoUrl: 'assets/logo.png',
    versaoHomolog: '2.3.2-001'
  };