import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-nova-localizacao-modal',
  templateUrl: './nova-localizacao-modal.page.html',
  styleUrls: ['./nova-localizacao-modal.page.scss'],
})
export class NovaLocalizacaoModalPage {

  novaLocalizacao: string;

  constructor(
    private modalCtrl: ModalController
  ) { }

  cancelar() {
    this.modalCtrl.dismiss({ valor: '' });
  }

  closeModal() {
    this.modalCtrl.dismiss({ valor: this.novaLocalizacao });
  }
}
