import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilLoaderService {

  public loading: HTMLIonLoadingElement;

  constructor(
    public loadingCtrl: LoadingController
  ) { }

  async showLoader(): Promise<boolean> {

    if (this.loading) {
      this.loading.dismiss();
    }
    this.loading = await this.loadingCtrl.create({
      message: 'Por favor aguarde ...'
    });

      this.loading.present();

      return true;
  }

  // Hide the loader if already created otherwise return error
  async hideLoader() {

    if (this.loading) {
      this.loading.dismiss();
    }
  }
  
}
