import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CisAuthService } from '../cis-auth.service';
import { take, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';

@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Input('pagina') pagina: string;
  @Input('cor') cor: string;
  
  formulario: UntypedFormGroup;

  showPassword = false;
  passwordToggleIcon = 'eye-outline';
  
  constructor(
    private fb: UntypedFormBuilder,
    private cisAuthService: CisAuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formulario = this.fb.group({
      usuario: [null, Validators.required],
      senha: [null, Validators.required]
    })
  }

  onSubmit() {
    console.log(this.formulario);
    let usuario: string = this.formulario.get("usuario").value;
    let senha: string = this.formulario.get("senha").value;

    this.cisAuthService.logar(usuario,senha)
    .pipe(
      take(1),
      /* catchError(err => {
        console.log(err);
        return of (null);
      }) */
    )
    .subscribe(res => {
      //this.obterUsuarioLogado();
      this.router.navigateByUrl(this.pagina);
    });
    //, err => console.log(err));
  }

  togglePassword(): void {

    if (this.showPassword) {
      this.passwordToggleIcon = 'eye-outline';
    } else {
      this.passwordToggleIcon =  'eye-off-outline';
    }
    this.showPassword = !this.showPassword;
  }
}
