import { CisApisService } from './cis-apis.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorDialogService } from './services/error-dialog.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  

  constructor(
    private service: CisApisService,
    private errorDialogService: ErrorDialogService
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.method === 'GET' ||
      ((request.method === 'POST' || request.method === "PUT") && !request.url.split("/").includes("security"))) {

      let token = this.service.getToken();

      //debugger
      if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
      }

      if (!request.url.split("/").includes("arquivos")) {
        if (!request.headers.has('Content-Type')) {
          request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
  
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
      }

      request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', '*') });
      request = request.clone({ headers: request.headers.set('Access-Control-Allow-Headers', 'X-Requested-With, Content-Type, Accept, Origin, Authorization') });
      request = request.clone({ headers: request.headers.set('Access-Control-Allow-Methods', 'GET') });

      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            console.log('event --->>> ', event);
          }
          return event;
        }),
        catchError((err: HttpErrorResponse) => {

          /*  let data = {};
           data = {
               reason: error && error.error.reason ? error.error.reason : '',
               status: error.status
           };
           // this.errorDialogService.openDialog(data);
           console.log(data); */
          //debugger
          let errorMessage = '';
          if (err.error instanceof ErrorEvent) {
            //console.log(1);
            // client-side error
            errorMessage = `Error: ${err.error.message}`;
          } else {

            
            switch (err.status) {
              case 0:
                // server-side error
                //errorMessage = `Código do erro: ${err.status} \n Mensagem: ${err.message}`;
                errorMessage = 'O sitema da COLORTEL encontra-se fora do ar. Tente novamente mais tarde ou entre em contato com o escritório.';
                //this.errorDialogService.presentAlert("Erro no servidor", request.url, errorMessage);
                this.errorDialogService.presentAlert("Erro no servidor", request.url, errorMessage);
                break;
            
              case 400:
                  let _msg: string;
                  let _alerta: any[];
                  let _valores: string[];

                  _alerta = err.error.ModelState;
                  _valores = Object.values(_alerta);

                 // _alerta = err.error.ModelState['##ALERTA##'];

                  //_msg = _valores.join('\n');
                  _msg = '<ul>';
                  //errorMessage = `Código do erro: ${err.status} \n Mensagem: \n ${_msg}`;
                  //errorMessage = `Erros detectados: \n ${_msg}`;
                  _valores.forEach(v => _msg += `<li>${v}</li>`);
                  _msg += '</ul>';
                  errorMessage = `<p>Os seguintes erros foram detectados:</p>${_msg}`;

                  this.errorDialogService.presentAlert("Request Erro", request.url, errorMessage);
                  break;

              case 500:
                errorMessage = `<p>Erro interno do sistema:</p><ul><li>${err.error.Message}</li></ul>`;
                this.errorDialogService.presentAlert("Request Erro", request.url, errorMessage);
                break;
/* 
              default:

                  // server-side error
                errorMessage = `Código do erro: ${err.status} \n Mensagem: ${err.message}`;
                this.errorDialogService.presentAlert("Request Erro", request.url, errorMessage);
                break; */
            }
            //return throwError(errorMessage);
          }
          //this.errorDialogService.presentAlert("Request Erro", request.url, errorMessage);
          return throwError(errorMessage);
        })
      )
    } else {

      if (request.url.split("/").includes("security")) {

        return next.handle(request).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              console.log('event --->>> ', event);
            }
            return event;
          }),
          catchError((err: HttpErrorResponse) => {
             
            //debugger
            let errorMessage = '';
            if (err.error instanceof ErrorEvent) {
              console.log(1);
              // client-side error
              errorMessage = `Error: ${err.error.message}`;
            } else {
  
              switch (err.status) {
                case 0:
                  // server-side error
                  errorMessage = "Verifique se o CIS está no ar";
                  this.errorDialogService.presentAlert("Erro no servidor", "", errorMessage);
                  break;
              
                case 400:  
                    errorMessage = err.error.error_description;
                      this.errorDialogService.presentAlert("Login inválido", "", errorMessage);
                    break;

              }
            }
  
            //this.errorDialogService.presentAlert("Request Erro", request.url, errorMessage);
  
            return throwError(errorMessage);
          })
        )
      } /* else {
        return next.handle(request);
      } */
    }
  }
}
