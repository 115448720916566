import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { CisAuthConfig } from './cis-auth.config';
import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';

import { CIS_AUTH_CONFIG } from './cis-auth.config.token';
import { LoginComponent } from './login/login.component';
import { CisAuthService } from './cis-auth.service';

/* export function SettingFactory() {
  return CisAuthModule.settings;
} */

//export const CIS_AUTH_CONFIG_SERVICE = new InjectionToken<CisAuthConfig>('CisAuthConfig');

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [LoginComponent]
})
export class CisAuthModule {

  static forRoot(cisAuthConfig: CisAuthConfig): ModuleWithProviders<CisAuthModule>{
    return {
      ngModule: CisAuthModule,
      providers: [        
        {
          provide: CIS_AUTH_CONFIG,
          useValue: cisAuthConfig
        }
      ]
    } 
 }
}
