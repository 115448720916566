import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilConfirmationService {

  constructor(private alertCtrl: AlertController) { }

  async confirmationAlert(
    header: string, 
    subHeader: string, 
    message: string): Promise<boolean> {
    
    let resolveFunction: (confirm: boolean) => void;
    
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });
    
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => resolveFunction(false)
        },
        {
          text: 'Sim',
          handler: () => resolveFunction(true)
        }
      ]
    });
    
    await alert.present();
    
    return promise;
  }  
}
