import { ToastController } from '@ionic/angular';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

import { AparelhoApiService } from './../../services/aparelho-api.service';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';

@Component({
  selector: 'cis-pesquisa-aparelho',
  templateUrl: './pesquisa-aparelho.component.html',
  styleUrls: ['./pesquisa-aparelho.component.scss']
})
export class PesquisaAparelhoComponent implements OnInit {

  @Input('cor') public cor: string;
  @Input('titulo') public titulo: string;
  @Output() listarAparelhos = new EventEmitter<any>();

  @Output('onPesquisaEtiquetaClicked') public onPesquisaEtiquetaClicked = new EventEmitter();
  @Output('onPesquisaNumeroSerieClicked') public onPesquisaNumeroSerieClicked = new EventEmitter();
  
  @Input('etiqueta') public etiqueta: string;
  @Input('numeroSerie') public numeroSerie: string;


  toast;
  tipoPesquisa: string;
  lista: any[];
  showLoader: boolean = false;

  readonly etiquetaMask: MaskitoOptions = {
    mask: [
      ...Array(3).fill(/\d/),
      ...Array('.'),
      ...Array(2).fill(/\d/), 
      ...Array('.'), 
      ...Array(3).fill(/\d/),
    ],
    overwriteMode: 'replace',
  };

  readonly maskPredicate: MaskitoElementPredicateAsync = async (el) => (el as HTMLIonInputElement).getInputElement();


  constructor(
    private aparelhoApiService : AparelhoApiService,
    private toastCtrl: ToastController
  ) { }

  ngOnInit(): void {
    this.tipoPesquisa = 'etiqueta';
    this.etiqueta = '';
  }

  radioGroupChange(event) {

    if (this.tipoPesquisa == "etiqueta") {
      this.numeroSerie = '';
    } else {
      this.etiqueta = "";      
    }
  }

  onPesquisarClick() {

    this.showProgressBar();

    this.aparelhoApiService.buscar(this.tipoPesquisa.toLowerCase(), this.tipoPesquisa == "etiqueta" ? this.etiqueta : this.numeroSerie)
      .subscribe(ret => {
        this.hideProgressBar();
        this.lista = [];
        if (ret) {
          if (Array.isArray(ret)){
            this.lista = [...ret];
          } else {
            this.lista.push(ret);
          }
        }
        //  else {
        //   this.presentToast("Aparelho não encontrado");
        // }

        this.listarAparelhos.emit(this.lista);
      });
  }

  async presentToast(mensagem: string) {
    this.toast = await this.toastCtrl.create({
      message: mensagem,
      position: 'middle',
      color: 'danger',
      buttons: [
        {
          text: "fechar",
        }
      ]
    });
    this.toast.present();
  }

  onPesquisaEtiquetaClick() {
    this.onPesquisaEtiquetaClicked.emit();
  }

  onPesquisaNumeroSerieClick() {
    this.onPesquisaNumeroSerieClicked.emit();
  }

  showProgressBar() {
    this.showLoader = true;
  }

  hideProgressBar() {
    this.showLoader = false;
  }

}
