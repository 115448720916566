<ion-header>
    <ion-toolbar [color]="cor">
        <ion-buttons slot="start">
        <ion-button (click)="setNovoAparelhoOpen(false)">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
        </ion-buttons>
        <ion-title>{{ titulo }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

    <ion-item>
      <ion-input
        label="Etiqueta"
        name="etiqueta"
        (ionChange)="onEtiquetaChange(asInputCustomEvent($event))"
        label-placement="floating"
        #autoFocus 
        type="tel" 
        inputmode="numeric"
        autocomplete="off"
        placeholder="Digite a etiqueta" 
        [clearInput]="true"
        [maskito]="etiquetaMask"
        [maskitoElement]="maskPredicate"
        [(ngModel)]="etiqueta">
      </ion-input>
    </ion-item>

    <ion-item>
      <ion-input
        (ionChange)="onNumeroSerieChange(asInputCustomEvent($event))"
        label="Nº Série"
        label-placement="floating"
        placeholder="Digite o nº de série"
        [clearInput]="true"
        [(ngModel)]="numeroSerie">
      </ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Tipo</ion-label>  
      <ionic-selectable (onChange)="onAparelhoTipoChanged($event)"
        [(ngModel)]="aparelhoTipo"
        itemValueField="Id"
        itemTextField="Nome"
        [items]="tipos"
        [hasVirtualScroll]="false"
        [canSearch]="true" 
        [canClear]="true"
        clearButtonText="Limpar"
        [shouldFocusSearchbar]="false" 
        [headerColor]="cor"
        searchPlaceholder="Digite o tipo de material">
        <ng-template ionicSelectableCloseButtonTemplate>
          <ion-icon name="close-circle" style="font-size: 24px;"></ion-icon>
        </ng-template>
      </ionic-selectable>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Material</ion-label>  
      <ionic-selectable (onChange)="onMaterialChanged($event)"
        [(ngModel)]="material"
        itemValueField="Id"
        itemTextField="Nome"
        [items]="materiais"
        [hasVirtualScroll]="false"
        [canSearch]="true" 
        [canClear]="true"
        clearButtonText="Limpar"
        [shouldFocusSearchbar]="false" 
        [headerColor]="cor"
        searchPlaceholder="Digite o nome do material">
        <ng-template ionicSelectableCloseButtonTemplate>
          <ion-icon name="close-circle" style="font-size: 24px;"></ion-icon>
        </ng-template>
      </ionic-selectable>
    </ion-item>

    <!-- <ion-footer> -->
        <div class="ion-text-center">
          <ion-button color="primary" size="small" (click)="onAparelhoInformarClick()" 
            [disabled]="!podeInformarAparelho">Informar aparelho</ion-button>
        </div>
    <!-- </ion-footer> -->

  </ion-content>

