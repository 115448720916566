import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { PesquisaAparelhoComponent } from '../pesquisa-aparelho/pesquisa-aparelho.component';

@Component({
  selector: 'cis-lista-aparelhos-pesquisados',
  templateUrl: './lista-aparelhos-pesquisados.component.html',
  styleUrls: ['./lista-aparelhos-pesquisados.component.scss']
})
export class ListaAparelhosPesquisadosComponent implements OnInit {

  @Input('tituloPesquisa') public tituloPesquisa: string = "Pesquisa de Aparelho";
  @Output('onPesquisaEtiquetaClicked') public onPesquisaEtiquetaClicked = new EventEmitter();
  @Output('onPesquisaNumeroSerieClicked') public onPesquisaNumeroSerieClicked = new EventEmitter();

  @Input('mostrarBtnSelecao') public mostrarBtnSelecao: boolean = false;
  @Input('corBtnSelecao') public corBtnSelecao: string;
  @Output('onBtnSelecaoClicked') public onBtnSelecaoClicked = new EventEmitter();
  //onAparelhoSelect

  @Input('mostrarBtnRecuperacao') public mostrarBtnRecuperacao: boolean = false;
  @Input('corBtnRecuperacao') public corBtnRecuperacao: string = "primary";
  @Output('onBtnRecuperacaoClicked') public onBtnRecuperacaoClicked = new EventEmitter();

  @ViewChild('pesquisa') pesquisa: PesquisaAparelhoComponent;

  constructor() { }

  aparelhos: any[] = [];

  onLimparClick(){
    this.aparelhos = [];
    this.pesquisa.etiqueta = "";
    this.pesquisa.numeroSerie = "";
  }

  onPesquisarClick(event) {
    this.aparelhos = event;
  }

  ngOnInit(): void {
    this.corBtnSelecao = this.corBtnSelecao === undefined ? "primary" : this.corBtnSelecao;
  }

  onBtnRecuperacaoClick(aparelho: any) {
    this.onBtnRecuperacaoClicked.emit(aparelho);
  }

  onPesquisaEtiquetaClick() {
    this.onPesquisaEtiquetaClicked.emit();
  }

  onPesquisaNumeroSerieClick() {
    this.onPesquisaNumeroSerieClicked.emit();
  }

  onBtnSelecaoClick(aparelho: any) {
    this.onBtnSelecaoClicked.emit(aparelho);
  }

}
