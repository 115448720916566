import { CisLocalDb } from './../models/cis-local-db';
import { Injectable, Inject } from '@angular/core';
import { CIS_LOCAL_DB_CONFIG } from '../cis-local-db.config.token';
import { CisLocalDbConfig } from '../cis-local-db.config';
import { Material } from '../models/material';
import { Observable, from } from 'rxjs';


@Injectable({providedIn: 'root'})
export class MaterialDbService {

    private _db: CisLocalDb;

    private getDB() {
        return this._db;
    }

    constructor(
        @Inject(CIS_LOCAL_DB_CONFIG) private cisLocalDbConfig: CisLocalDbConfig
    ) { 

        if(this._db == null) {

            this._db = new CisLocalDb();
            if (!this._db.isOpen()){
                this._db.open();
            }
        }
    }

    async obterPorTipo(tipoId:number) : Promise<Material[]> {

        let db = this.getDB();

        //return db.transaction('r', db.material, async () => {
        return await db.material.where("Tipo_Id").equals(tipoId).sortBy('Nome');
        //});
    }

    async obterPorCategoria(categoriaId:number) : Promise<Material[]> {

        let db = this.getDB();

        return await db.material.where("Categoria_Id").equals(categoriaId).sortBy('Nome');
    }

    listar() {

        let db = this.getDB();
        
        return db.material.toArray();
    }
    
    incluirArray(materiais: Material[]) : Promise<number>{

    //debugger
    let db = this.getDB();

    return db.transaction('rw', db.material, async () => {

        db.material.clear();
        return await db.material.bulkAdd(materiais).then(ret => {

                let chave = JSON.parse(localStorage.getItem(this.cisLocalDbConfig.aplicativo));
                if (chave.updates == null) {
                    chave.updates = {};
                  }
                chave.updates.material = Date.now();

                localStorage.setItem(this.cisLocalDbConfig.aplicativo, JSON.stringify(chave));

                return db.material.count();    
            })
        }).catch(error => {
            console.log(error);
            return null;
        });
    }

    incluirArrayFrom(materiais: Material[]) : Observable<number> {

        let db = this.getDB();
    
        return from(db.transaction('rw', db.material, async () => {
    
          db.material.clear();

          return await db.material.bulkAdd(materiais).then(ret => {
                let chave = JSON.parse(localStorage.getItem(this.cisLocalDbConfig.aplicativo));
                if (chave.updates == null) {
                    chave.updates = {};
                }
                chave.updates.material = Date.now();

                localStorage.setItem(this.cisLocalDbConfig.aplicativo, JSON.stringify(chave));

                return db.material.count();    
            });
        }));
      }


    contar() : Promise<number> {

        let db = this.getDB();
        return db.material.count();
    }
    
}