import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  /* {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full'
  }, */
  {
    path: 'home',
    canActivateChild: [
      AuthGuard
    ],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'atividades',
    canActivateChild: [
      AuthGuard
    ],
    children:[ 
      {
        path: '',
        loadChildren: () => import('./atividade/atividade-start/atividade-start.module').then( m => m.AtividadeStartPageModule)
      },
      {
        path: 'filter',
        loadChildren: () => import('./atividade/atividade-filter/atividade-filter.module').then( m => m.AtividadeFilterPageModule)
      },
      {
        path: 'list/:tipo',
        loadChildren: () => import('./atividade/atividade-list/atividade-list.module').then( m => m.AtividadeListPageModule)  
      },
      {
        path: 'confirm',
        loadChildren: () => import('./atividade/atividade-confirm/atividade-confirm.module').then( m => m.AtividadeConfirmPageModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./atividade/atividade-register/atividade-register.module').then( m => m.AtividadeRegisterPageModule)
      },
      {
        path: 'instalacao-simples',
        loadChildren: () => import('./atividade/atividade-instalacao-simples-register/atividade-instalacao-simples-register.module').then( m => m.AtividadeInstalacaoSimplesRegisterPageModule)
      },
      {
        path: 'instalacao-split',
        loadChildren: () => import('./atividade/atividade-instalacao-split-register/atividade-instalacao-split-register.module').then( m => m.AtividadeInstalacaoSplitRegisterPageModule)
      },
      {
        path: 'retirada',
        loadChildren: () => import('./atividade/atividade-retirada-register/atividade-retirada-register.module').then( m => m.AtividadeRetiradaRegisterPageModule)
      },
      {
        path: 'corretiva',
        loadChildren: () => import('./atividade/atividade-corretiva-register/atividade-corretiva-register.module').then( m => m.AtividadeCorretivaRegisterPageModule)
      },
      {
        path: 'recuperacao',
        loadChildren: () => import('./atividade/atividade-recuperacao/recuperacao-iniciar/recuperacao-iniciar.module').then( m => m.RecuperacaoIniciarPageModule)
      },
      {
        path: 'corretiva-controle-remoto',
        loadChildren: () => import('./atividade/atividade-corretiva-controle-remoto-register/atividade-corretiva-controle-remoto-register.module').then( m => m.AtividadeCorretivaControleRemotoRegisterPageModule)
      },
    ]
  },
  {
    path: 'pmocs',
    canActivateChild: [
      AuthGuard
    ],
    children: [
      {
        path: '',
        loadChildren: () => import('./pmoc/pmoc-list/pmoc-list.module').then( m => m.PmocListPageModule),
      },
    ]
  },
  {
    path: 'aparelhos',
    canActivateChild: [
      AuthGuard
    ],
    children: [
      {
        path: '',
        loadChildren: () => import('./aparelhos/pre-entrega-listagem/pre-entrega-listagem.module').then( m => m.PreEntregaListagemPageModule),
      }    
    ]
  },
  {
    path: 'relatorios',
    canActivateChild: [
      AuthGuard
    ],
    children:[
      {
        path: '',
        loadChildren: () => import('./relatorio/relatorio-filter/relatorio-filter.module').then( m => m.RelatorioFilterPageModule)
      },
      {
        path: 'atividades',
        loadChildren: () => import('./relatorio/relatorio-atividade-list/relatorio-atividade-list.module').then( m => m.RelatorioAtividadeListPageModule)
      },
      {
        path: 'dados',
        loadChildren: () => import('./relatorio/relatorio-dados/relatorio-dados.module').then( m => m.RelatorioDadosPageModule)
      },
      {
        path: 'visualizar',
        loadChildren: () => import('./relatorio/relatorio-visualizar/relatorio-visualizar.module').then( m => m.RelatorioVisualizarPageModule)
      },    
    ]
  },
  {
    path: 'dados',
    canActivateChild: [
      AuthGuard
    ],
    children: [
      {
        path: '',
        loadChildren: () => import('./dados/carregar-dados/carregar-dados.module').then( m => m.CarregarDadosPageModule)
      },
      {
        path: 'descarregar',
        loadChildren: () => import('./dados/descarregar-dados/descarregar-dados.module').then( m => m.DescarregarDadosPageModule)
      },          
    ]    
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'sobre',
    canActivateChild: [
      AuthGuard
    ],
    loadChildren: () => import('./sobre/sobre.module').then( m => m.SobrePageModule)
  },
  {
    path: 'consultar-aparelho',
    canActivateChild: [
      AuthGuard
    ],
    loadChildren: () => import('./consultar-aparelho/consultar-aparelho.module').then( m => m.ConsultarAparelhoPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
