import { UtilNetworkService } from './../../services/util-network.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'util-util-network-label',
  templateUrl: './util-network-label.component.html',
  styleUrls: ['./util-network-label.component.scss']
})
export class UtilNetworkLabelComponent implements OnInit {

  constructor(
    public networkService: UtilNetworkService 
  ) { }

  ngOnInit(): void {
  }

}
