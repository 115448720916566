import Dexie from 'dexie';
import { IAparelhoTipo } from '../interfaces/i-aparelho-tipo';
import { AparelhoTipo } from './aparelho-tipo';
import { IAparelho } from '../interfaces/i-aparelho';
import { Aparelho } from './aparelho';
import { IMaterial } from '../interfaces/i-material';
import { Material } from './material';
import { ICategoria } from '../interfaces/i-categoria';
import { Categoria } from './categoria';
import { IVeiculo } from '../interfaces/i-veiculo';
import { Veiculo } from './veiculo';

export class CisLocalDb extends Dexie {

    aparelhoTipo: Dexie.Table<IAparelhoTipo,number>;
    aparelho: Dexie.Table<IAparelho,number>;
    material: Dexie.Table<IMaterial,number>;
    categoria: Dexie.Table<ICategoria,number>;
    veiculo: Dexie.Table<IVeiculo,number>;
    
    constructor() {

        const options = {
            autoOpen: true
        }

        super('CisLocalDb', options);

        const db = this;

        db.version(1).stores({
            aparelhoTipo: 'Id,Nome',
            aparelho: 'Id,Etiqueta,NumeroSerie,Material.Id,Categoria.Id,Tipo.Id',
            material: 'Id,Nome,Tipo_Id',
        });

        db.version(2).stores({
            aparelho: 'Id,Etiqueta,NumeroSerie,Material.Id,Categoria.Id,Tipo.Id,*Etiquetas',
            material: 'Id,Nome,Tipo_Id,Categoria_Id',
            categoria: 'Id,Nome,Tipo_Id',
        });

        db.version(3).stores({
            veiculo:'Id,Placa',
        });

        db.aparelhoTipo.mapToClass(AparelhoTipo);
        db.aparelho.mapToClass(Aparelho);
        db.material.mapToClass(Material);
        db.categoria.mapToClass(Categoria);
        db.veiculo.mapToClass(Veiculo);
    }

}