import { Component, Input } from '@angular/core';
import { InputCustomEvent, ModalController, NavParams } from '@ionic/angular';

import { AparelhoTipo } from '../../models/aparelho-tipo';
import { Material } from '../../models/material';
import { AparelhoTipoDbService } from '../../services/aparelho-tipo-db.service';
import { MaterialDbService } from '../../services/material-db.service';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'cis-novo-aparelho-info',
  templateUrl: './novo-aparelho-info.component.html',
  styleUrls: ['./novo-aparelho-info.component.css']
})
export class NovoAparelhoInfoComponent {

  cor: string;
  titulo: string;

  tipos: AparelhoTipo[];
  materiais: Material[];

  etiqueta: string;
  numeroSerie: string;
  aparelhoTipo: AparelhoTipo;
  material: Material;
  podeInformarAparelho: boolean = false;

  readonly etiquetaMask: MaskitoOptions = {
    mask: [
      ...Array(3).fill(/\d/),
      ...Array('.'),
      ...Array(2).fill(/\d/), 
      ...Array('.'), 
      ...Array(3).fill(/\d/),
    ],
    overwriteMode: 'replace'
  };

  readonly maskPredicate: MaskitoElementPredicateAsync = async (el) => (el as HTMLIonInputElement).getInputElement();

  constructor(
    private tipoService: AparelhoTipoDbService,
    private materialService: MaterialDbService,
    private navParams: NavParams,
    private modalController: ModalController
  ) { }

  ngOnInit(): void {
    this.titulo = this.navParams.data.titulo;
    this.cor = this.navParams.data.cor;

    this.setNovoAparelhoOpen(true);
  }

   setNovoAparelhoOpen(isOpen: boolean) {

    this.etiqueta = null;
    this.numeroSerie = null;
    this.aparelhoTipo = null;
    this.material = null;

    if (isOpen) {

      if (!this.tipos) {
        this.carregarTipos();
      }
    } else {
      this.modalController.dismiss();
    }
  }
  
   async carregarTipos() {
    
    this.tipos = await this.tipoService.listar();
  }

  async listarMateriais(tipoId: number) {

    this.materiais = await this.materialService.obterPorTipo(tipoId);
  }

  onEtiquetaChange(inputEvent: InputCustomEvent) {
    this.setBtnAparelhoInformar();
  }

  onNumeroSerieChange(inputEvent: InputCustomEvent) {
    this.setBtnAparelhoInformar();
  }

  onAparelhoTipoChanged(event: {
    component: IonicSelectableComponent,
    value: AparelhoTipo
  }) {

    this.aparelhoTipo = event.value;

    if (this.aparelhoTipo) {
      this.listarMateriais(this.aparelhoTipo.Id);
    } else {
      this.materiais = null;
      this.material = null;
    }
  }

  onMaterialChanged(event: {
    component: IonicSelectableComponent,
    value: Material
  }) {

    this.material = event.value;

    this.setBtnAparelhoInformar()
  }

  asInputCustomEvent(event: Event) {
    return event as InputCustomEvent;
  }

  setBtnAparelhoInformar() {

    this.podeInformarAparelho = this.etiqueta 
                            && this.numeroSerie 
                            && this.material != null 
                            && this.aparelhoTipo != null;
  }

  onAparelhoInformarClick() {

    this.modalController.dismiss({
      aparelho: {
        Id: undefined,
        Etiqueta: this.etiqueta,
        NumeroSerie: this.numeroSerie,
        Tipo: this.aparelhoTipo,
        Material: this.material
      }
    });
  }
}
