import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { IAparelho } from './interfaces/i-aparelho';
import { IAtividade } from './interfaces/i-atividade';
import { CIS_APIS_CONFIG } from './cis-apis.config.token';
import { CisApisConfig } from './cis-apis.config';

@Injectable({
  providedIn: 'root'
})
export class CisApisService {
  baseUrl = this.cisApisConfig.apiUrl;
  aplicativo = this.cisApisConfig.aplicativo;

   constructor(
     @Inject(CIS_APIS_CONFIG) private cisApisConfig: CisApisConfig,
     private http: HttpClient) {}
   
   getToken(): string {

    const chave = JSON.parse( localStorage.getItem(this.aplicativo) );
    return chave ? chave.tokenAcesso : null;
  }

  getAll() : Observable<IAparelho[]> {

    //const headers = new HttpHeaders()
    //        .set("Authorization", `Bearer ${this.token}`);
    //let url = `${environment.apiUrl}/aparelhos`;

    return this.http.get<IAparelho[]>(`${this.baseUrl}/aparelhos`) //, {headers})
    .pipe(
      tap(aparelhos => console.log('fetched aparelhos')),
      catchError(this.handleError('AparelhoApiService.getAll', []))
    );
  }

  listarAtividades(tecnicoId: number) : Observable<IAtividade[]> {

    //let tecnicoId = this.usuarioId;
    let dataAgendamento = '2020-05-28';

    const params = new HttpParams({
      fromString: `tecnicoId=${tecnicoId}&dataAgendamento=${dataAgendamento}`
    });

    return this.http.get<IAtividade[]>(`${this.baseUrl}/atividades`, {params})
    .pipe(
      tap(aparelhos => console.log('fetched atividades')),
      catchError(this.handleError('listarAtividades', []))
    );
  }

  /* listarAparelhoTipos() : Observable<IAparelhoTipo[]> {

    let url = `${this.baseUrl}/aparelhosTipos`;

    return this.http.get<IAparelhoTipo[]>(url) //, httpOptions)
    .pipe(
      tap(tipos => console.log('fetched tipos de aparelhos')),
      catchError(this.handleError('listarAparelhoTipos', []))
    );
  } */

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
} 