import { SwUpdate } from '@angular/service-worker';
import { ApplicationRef, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { interval, concat } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilApplicationService {

  constructor(
    private appRef: ApplicationRef,
    private alertController: AlertController,
    private update: SwUpdate,
  ) { 
     // Allow the app to stabilize first, before starting polling for updates with `interval()`.
     const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
     const everySixHours$ = interval(6 * 60 * 60 * 1000);
     const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
 
     everySixHoursOnceAppIsStable$.subscribe(() => this.update.checkForUpdate());
  }

  registerForUpdates() {
    if (this.update.isEnabled) {
      this.update.available.subscribe(() => 
        this.update.activateUpdate()
          .then(() => document.location.reload())
        // this.promptUser()
      );
    }
  }

  private async promptUser() {
    const alert = await this.alertController.create({
      header: 'Atualização Disponível',
      message:
        'Existe atualização disponível para este app. Gostaria de reiniciar o app agora para obter esta atualização?',
      buttons: [
        { text: 'Sim', role: 'confirm' },
        { text: 'Não', role: 'cancel' },
      ],
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    if (result.role === 'confirm') {
      this.update.activateUpdate().then(() => document.location.reload());
    }
  }
}
