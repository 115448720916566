import { NgModule, ModuleWithProviders } from '@angular/core';
import { CisLocalDbComponent } from './cis-local-db.component';
import { CisLocalDbConfig } from './cis-local-db.config';
import { CIS_LOCAL_DB_CONFIG } from './cis-local-db.config.token';
import { NovoAparelhoInfoComponent } from './components/novo-aparelho-info/novo-aparelho-info.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableCloseButtonTemplateDirective, IonicSelectableComponent } from 'ionic-selectable';
import { NovoAparelhoInfoModule } from './components/novo-aparelho-info/novo-aparelho-info.module';

@NgModule({
  declarations: [],
  imports: [
    NovoAparelhoInfoModule
  ],
  exports: []
})
export class CisLocalDbModule {

  static forRoot(cisLocalDbConfig: CisLocalDbConfig): ModuleWithProviders<CisLocalDbModule>{
    return {
      ngModule: CisLocalDbModule,
      providers: [
        {
          provide: CIS_LOCAL_DB_CONFIG,
          useValue: cisLocalDbConfig
        }
      ]
    }
  }
 }
