import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'util-util-error-msg',
  templateUrl: './util-error-msg.component.html',
  styleUrls: ['./util-error-msg.component.scss']
})
export class UtilErrorMsgComponent implements OnInit {

  @Input() controlArray: UntypedFormArray;
  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() mensagem: string;

  constructor() { }

  ngOnInit(): void {
  }

  get errorMessage() {

    if (this.control) {
      for(const propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName) &&
            (this.control.touched || this.control.dirty)) {
  
              return this.getErrorMsg(this.label, propertyName, this.control.errors[propertyName] );
        }
      }
    }

    if (this.controlArray) {
      for(const propertyName in this.controlArray.errors) {
        if (this.controlArray.errors.hasOwnProperty(propertyName) &&
        (this.controlArray.touched || this.controlArray.dirty)) {
  
          return this.mensagem ? this.mensagem : this.getErrorMsg(this.label, propertyName, this.controlArray.errors[propertyName] );
        }
      }
    }

    return null;
  }

  getErrorMsg(fieldName: string, validatorName: string, validatorValue?: any) {
    const config = {
      'required': `${fieldName} é obrigatório.`,
      'minlength': `${fieldName} precisa ter no mínimo ${validatorValue.requiredLength} caracteres.`,
      'maxlength': `${fieldName} precisa ter no máximo ${validatorValue.requiredLength} caracteres.`,
      'cepInvalido': 'CEP inválido.',
      'emailInvalido': 'Email já cadastrado!',
      'equalsTo': 'Campos não são iguais',
      'invalidQuilometragem': `${fieldName} inválida.`,
      'pattern': 'Campo inválido',
      'invalidTipo': `${fieldName} inválido.`,
      'invalidQtdAparelhos': 'Quantidade inválida de aparelhos',
      'romaneioNaoConferido': 'Existe romaneio NÃO conferido para este veículo',
      'motoristaInexistente': 'Motorista não consta na lista de técnicos do romaneio'
    };

    return config[validatorName];
  }
}