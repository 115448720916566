<cis-pesquisa-aparelho 
    #pesquisa
    [titulo]="tituloPesquisa" 
    (listarAparelhos)="onPesquisarClick($event)"
    (onPesquisaEtiquetaClicked)="onPesquisaEtiquetaClick()"
    (onPesquisaNumeroSerieClicked)="onPesquisaNumeroSerieClick()">
</cis-pesquisa-aparelho>
<ion-list >
  <ion-list-header>
    <ion-label>Total: {{ aparelhos.length }}</ion-label>
    <ion-button (click)="onLimparClick()">
      <ion-icon slot="start" name="close-outline"></ion-icon>
      Limpar
    </ion-button>
  </ion-list-header>

    <div *ngFor="let aparelho of aparelhos">
        <ion-item>
          <ion-label>
            <h1>{{aparelho.UltimaEtiqueta}}</h1>
            <p><small>Nº Série: {{aparelho.NumeroSerie}}</small></p>
          </ion-label>
  
          <ion-note>
            <p>{{ aparelho.AparelhoEstado_Id_Display}}</p>
            <h5 *ngIf="aparelho.BaixaAtivo"><ion-text color="danger">BAIXADO</ion-text></h5>
          </ion-note>
        </ion-item>

        <ion-item  lines="none">
          <ion-label>

            <ion-row>
              <ion-col size="3">Categoria:</ion-col>
              <ion-col size="9" class="ion-text-wrap">{{aparelho.Categoria_Id_Display}}</ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="3">Material:</ion-col>
              <ion-col size="9" class="ion-text-wrap">{{aparelho.Material_Id_Display}}</ion-col>
            </ion-row>
  
            <ion-row>
              <ion-col size="3">Marca:</ion-col>
              <ion-col size="9">{{ aparelho.Marca }}</ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="3">Tipo:</ion-col>
              <ion-col size="9" class="ion-text-wrap">{{aparelho.Tipo_Id_Display}}</ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="3">Proprietário:</ion-col>
              <ion-col size="9" class="ion-text-wrap">{{aparelho.Proprietario_Id_Display}}</ion-col>
            </ion-row>
  
            <ion-row>
              <ion-col size="3">Pessoa:</ion-col>
              <ion-col size="9" class="ion-text-wrap">{{aparelho.Pessoa_Id_Display}}</ion-col>
            </ion-row>

            <ion-row *ngIf="aparelho.Estoque_Id">
              <ion-col size="3">Estoque:</ion-col>
              <ion-col size="9">{{aparelho?.Estoque_Id_Display}}</ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="3">Localização:</ion-col>
              <ion-col size="9">{{aparelho?.Localizacao}}</ion-col>
            </ion-row>

            <div *ngIf="aparelho.Etiquetas.length > 1">
              <ion-row *ngFor="let e of aparelho.Etiquetas | sortBy:'desc'; first as isFirst">
                <ion-col size="3"><span *ngIf="isFirst" class="etiquetas" >Etiquetas:</span></ion-col>
                <ion-col size="9">{{e.Codigo}}</ion-col>
              </ion-row>  
            </div>

          </ion-label>
        </ion-item>

        <ion-item button [color]="corBtnSelecao" class="ion-margin-bottom" (click)="onBtnSelecaoClick(aparelho)" *ngIf="mostrarBtnSelecao">
          <ion-label>Selecionar este aparelho</ion-label>
          <ion-icon slot="start" name="checkmark-outline"></ion-icon>
        </ion-item>

        <ion-item button [color]="corBtnRecuperacao" class="ion-margin-bottom" (click)="onBtnRecuperacaoClick(aparelho)"  *ngIf="mostrarBtnRecuperacao">
          <ion-label>Abrir atividade recuperação</ion-label>
          <ion-icon slot="start" name="build"></ion-icon>
        </ion-item>

      </div>
</ion-list>



