<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list" *ngIf="usuario">

          <ion-list-header>
            <ion-item lines="none">
              <ion-avatar slot="start">
                <img src="./../assets/avatar.svg">
                <!-- <img src="./../assets/eu.jpeg"> -->
              </ion-avatar>
              <ion-label>
                <h2 class="ion-text-wrap">{{usuario.Pessoa_Id_Display}}</h2>
                <p>{{usuario.Login }}</p>
              </ion-label>
            </ion-item>   
          </ion-list-header>

          <div *ngFor="let p of appPages;let i = index">

            <ion-menu-toggle *ngIf="p.url">
                <ion-item (click)="selectedIndex = i" routerDirection="root" [routerLink]="p.url" lines="none" [class.active]="selectedIndex == i">
                  <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                  <ion-label>{{ p.title }}</ion-label>
                </ion-item>
            </ion-menu-toggle>

            <ion-item button *ngIf="p.children?.length > 0" (click)="p.open = !p.open"
              [class.active-parent]="p.open" detail="false">
              <ion-icon slot="start" ios="caret-forward-outline" md="caret-forward-sharp" *ngIf="!p.open"></ion-icon>
              <ion-icon slot="start" ios="caret-back-outline" md="caret-back-sharp" *ngIf="p.open"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>

            <ion-list *ngIf="p.open">
              <ion-menu-toggle>
                <ion-item *ngFor="let sub of p.children;let j = index" class="sub-item" (click)="selectedIndex = i;selectedSubItem=j;"
                    routerDirection="root" [routerLink]="sub.url" lines="none" [class.active]="selectedIndex==i && selectedSubItem==j">
                  <ion-icon slot="start" [ios]="sub.icon + '-outline'" [md]="sub.icon + '-sharp'"></ion-icon>
                  <ion-label>{{ sub.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>

            <ion-menu-toggle *ngIf="p.url==''">
              <ion-item (click)="onLogout()" lines="none" detail="false" [class.active]="selectedIndex == i">
                <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
