import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { UtilsConfig } from '../utils.config';
import { UTILS_CONFIG } from '../utils.config.token';

// const STORAGE_REQ_KEY = this. ''; // this.utilsConfig. environment.chaveRequestOffline;
// const STORAGE_FAILED_REQ_KEY = ''; //environment.chaveRequestErroOffline;

interface StoredRequest {
  url: string;
  type: string;
  data?: any;
  params?: string;
  base64Str?: string;
  fileName?: string;
  base64Str2?: string;
  fileName2?: string;
  time: number;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class UtilOfflineManagerService {

  STORAGE_REQ_KEY = this.utilsConfig.chaveRequestOffline;
  STORAGE_FAILED_REQ_KEY = this.utilsConfig.chaveRequestErroOffline;

  constructor(
    @Inject(UTILS_CONFIG) private utilsConfig: UtilsConfig,
    private storage: Storage,
    private http: HttpClient,
    private toastController: ToastController
  ) { }

  checkForEvents(): Observable<any> {
    return from(this.storage.get(this.STORAGE_REQ_KEY)).pipe(
      switchMap(storedOperations => {
        const storedObj = JSON.parse(storedOperations);
        if (storedObj && storedObj.length > 0) {
          return this.sendRequests(storedObj).pipe(
            finalize(() => {
              const toast = this.toastController.create({
                message: `Dados locais sincronizados com sucesso!`,
                duration: 3000,
                position: 'bottom'
              });
              // tslint:disable-next-line: no-shadowed-variable
              toast.then(toast => toast.present());

              this.storage.remove(this.STORAGE_REQ_KEY);
            })
          )
        }
        // tslint:disable-next-line: one-line
        else {
          //console.log('no local events to sync');
          return of(false);
        }
      })
    )
  }

  async storeRequest(url, type, data, params=null, base64Str=null, fileName=null) {
    const toast = this.toastController.create({
      message: `Realização gravada localmente pois o celular está offline.`,
      duration: 3000,
      position: 'bottom'
    });
    // tslint:disable-next-line: no-shadowed-variable
    toast.then(toast => {
      return toast.present();
    });

    const action: StoredRequest = {
      // tslint:disable-next-line: object-literal-shorthand
      url: url,
      // tslint:disable-next-line: object-literal-shorthand
      type: type,
      // tslint:disable-next-line: object-literal-shorthand
      data: data,
      params: params,
      base64Str: base64Str,
      fileName: fileName,
      time: new Date().getTime(),
      id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
    };
    // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript

    return this.storage.get(this.STORAGE_REQ_KEY).then(storedOperations => {
      let storedObj = JSON.parse(storedOperations);

      if (storedObj) {
        storedObj.push(action);
      } else {
        storedObj = [action];
      }
      // Save old & new local transactions back to Storage
      return this.storage.set(this.STORAGE_REQ_KEY, JSON.stringify(storedObj));
    });
  }

  async storeRequestWithFiles(url, type, data, params=null, base64Str1= null, fileName1=null, base64Str2 = null, fileName2=null) {
    const toast = this.toastController.create({
      message: `Realização gravada localmente pois o celular está offline.`,
      duration: 3000,
      position: 'bottom'
    });
    // tslint:disable-next-line: no-shadowed-variable
    toast.then(toast => {
      return toast.present();
    });

    const action: StoredRequest = {
      // tslint:disable-next-line: object-literal-shorthand
      url: url,
      // tslint:disable-next-line: object-literal-shorthand
      type: type,
      // tslint:disable-next-line: object-literal-shorthand
      data: data,
      params: params,
      base64Str: base64Str1,
      fileName: fileName1,
      base64Str2: base64Str2,
      fileName2: fileName2,
      time: new Date().getTime(),
      id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
    };
    // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript

    return this.storage.get(this.STORAGE_REQ_KEY).then(storedOperations => {
      let storedObj = JSON.parse(storedOperations);

      if (storedObj) {
        storedObj.push(action);
      } else {
        storedObj = [action];
      }
      // Save old & new local transactions back to Storage
      return this.storage.set(this.STORAGE_REQ_KEY, JSON.stringify(storedObj));
    });
  }

  async storeFailedRequest(data) {
    
    let dados = [];

    Object.keys(data).forEach(function(key, index) {
      if (data[index] !== null)
        dados.push(data[index])
    });

    if (dados.length > 0) {
      this.storage.remove(this.STORAGE_FAILED_REQ_KEY);
 
      let action: any = {
        atividades: dados,
        date: Date.now(),
        time: new Date().getTime(),
        id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
      };
    
      return this.storage.get(this.STORAGE_FAILED_REQ_KEY)
        .then(storedOperations => {
          let storedObj = JSON.parse(storedOperations);

          if (storedObj) {
            storedObj.push(action);
          } else {
            storedObj = [action];
          }
          // Save old & new local transactions back to Storage
          return this.storage.set(this.STORAGE_FAILED_REQ_KEY, JSON.stringify(storedObj));
        });
      }
      else {
        return null;
      }
  }
  
  sendRequests(operations: StoredRequest[]) {
    const obs = [];

    for (const op of operations) {

     if (op.fileName) {

        if (op.fileName2) {

          const params = new HttpParams({
            fromString: op.params
          });
  
          const _blob1 = this.b64toBlob(op.base64Str, 'application/png');
          const _blob2 = this.b64toBlob(op.base64Str2, 'application/png');

          const formData = new FormData();
          formData.append('file', _blob1, op.fileName);
          formData.append('file', _blob2, op.fileName2);
  
          const formaDataObs = this.http.post<any>(op.url, formData, {params: params})
          .pipe(catchError(error => of (op.data)));
  
          obs.push(formaDataObs);
  
        } else {
          const params = new HttpParams({
            fromString: op.params
          });
  
          const _blob = this.b64toBlob(op.base64Str, 'application/pdf');
  
          const formData = new FormData();
          formData.append('file', _blob, op.fileName);
  
          const formaDataObs = this.http.post<any>(op.url, formData, {params: params})
          .pipe(catchError(error => of (op.data)));
  
          obs.push(formaDataObs);
        }
      }
      else {
        const oneObs = this.http.request(op.type, op.url, {body: op.data})
        .pipe(catchError(error => of (op.data)));
          //.pipe(catchError(error => of (`Erro na atividade: ${op.data.Id}`)));

          obs.push(oneObs);
      }
    }
    // Send out all local events and return once they are finished
    //return forkJoin(obs);
    return forkJoin(obs)
  }

   private b64toBlob (b64Data, contentType='', sliceSize=512) {
 
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  async createBlob(base64) {
    let res =    await fetch(base64)
    let myBlob = await res.blob()
  
    return myBlob;
  } 

}