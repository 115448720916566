import { CisLocalDb } from './../models/cis-local-db';
import { Injectable, Inject } from '@angular/core';
import { AparelhoTipo } from '../models/aparelho-tipo';
import { CIS_LOCAL_DB_CONFIG } from '../cis-local-db.config.token';
import { CisLocalDbConfig } from '../cis-local-db.config';
import { Observable, from } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AparelhoTipoDbService {

    private _db: CisLocalDb;

    private getDB() {
        return this._db;
    }

    constructor(
        @Inject(CIS_LOCAL_DB_CONFIG) private cisLocalDbConfig: CisLocalDbConfig
    ) { 

        if(this._db == null) {

            this._db = new CisLocalDb();
            if (!this._db.isOpen()){
                this._db.open();
            }
        }
    }

    listar() {

        let db = this.getDB();
        
        return db.aparelhoTipo.toArray();
    }
    
    incluirArray(tipos: AparelhoTipo[]) : Promise<number>{

    //debugger
    let db = this.getDB();

    return db.transaction('rw', db.aparelhoTipo, async () => {

        db.aparelhoTipo.clear();
        return await db.aparelhoTipo.bulkAdd(tipos).then(ret => {

                let chave = JSON.parse(localStorage.getItem(this.cisLocalDbConfig.aplicativo));
                if (chave.updates == null) {
                    chave.updates = {};
                  }
                chave.updates.aparelhoTipo = Date.now();

                localStorage.setItem(this.cisLocalDbConfig.aplicativo, JSON.stringify(chave));

                return db.aparelhoTipo.count();    
            })
        }).catch(error => {
            console.log(error);
            return null;
        });
    }

    incluirArrayFrom(tipos: AparelhoTipo[]) : Observable<number> {

        let db = this.getDB();
    
        return from(db.transaction('rw', db.aparelhoTipo, async () => {
    
          db.aparelhoTipo.clear();

          return await db.aparelhoTipo.bulkAdd(tipos).then(ret => {

                let chave = JSON.parse(localStorage.getItem(this.cisLocalDbConfig.aplicativo));
                if (chave.updates == null) {
                    chave.updates = {};
                }
                chave.updates.aparelhoTipo = Date.now();

                localStorage.setItem(this.cisLocalDbConfig.aplicativo, JSON.stringify(chave));

                return db.aparelhoTipo.count();    
            });
        }));
      }
    

    contar() : Promise<number> {

        let db = this.getDB();
        return db.aparelhoTipo.count();
    }
    
}