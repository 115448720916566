<ion-list>
    <ion-list-header>
      <ion-label>Total de aparelhos: {{ aparelhos.length }}</ion-label>
      <ion-button (click)="onLimparClick()" color="danger" fill="outline" size="small">
        <ion-icon slot="start" name="close-outline"></ion-icon>
        Limpar
      </ion-button>
    </ion-list-header>
  
    <div *ngFor="let aparelho of aparelhos">
      <ion-item>
        <ion-label>
          <h1>{{aparelho.UltimaEtiqueta ? aparelho.UltimaEtiqueta : aparelho.Etiqueta }}</h1>
          <p><small>Nº Série: {{aparelho.NumeroSerie}}</small></p>
        </ion-label>
  
        <ion-note>
          <h4>{{ aparelho.AparelhoEstado_Id_Display}}</h4>
        </ion-note>
      </ion-item>
  
      <ion-item lines="none">
        <ion-label>
          <ion-row>
            <ion-col size="4">Tipo:</ion-col>
            <ion-col size="8">{{ aparelho.Tipo_Id_Display ? aparelho.Tipo_Id_Display : aparelho.Tipo.Nome }}</ion-col>
          </ion-row>
  
          <ion-row>
            <ion-col size="4">Categoria:</ion-col>
            <ion-col size="8">{{ aparelho.Categoria_Id_Display ? aparelho.Categoria_Id_Display : aparelho.Categoria.Nome }}</ion-col>
          </ion-row>
  
          <ion-row>
            <ion-col size="4">Material:</ion-col>
            <ion-col size="8">{{ aparelho.Material_Id_Display ? aparelho.Material_Id_Display : aparelho.Material.Nome }}</ion-col>
          </ion-row>
  
  <!--         <ion-row>
            <ion-col size="4">Marca:</ion-col>
            <ion-col size="8">{{ aparelho.Marca }}</ion-col>
          </ion-row>
   -->
  
          <ion-row *ngIf="aparelho.Proprietario_Id_Display">
            <ion-col size="4">Proprietário:</ion-col>
            <ion-col size="8">{{aparelho.Proprietario_Id_Display}}</ion-col>
          </ion-row>
  
          <ion-row *ngIf="aparelho.Pessoa_Id_Display">
            <ion-col size="4">Pessoa:</ion-col>
            <ion-col size="8">{{aparelho.Pessoa_Id_Display}}</ion-col>
          </ion-row>
  
   <!--        <ion-row *ngIf="mostrarEstoque(aparelho.EstoqueId)">
            <ion-col size="4">Estoque:</ion-col>
            <ion-col size="8">{{aparelho?.Estoque_Id_Display}}</ion-col>
          </ion-row> -->

          <ion-row *ngIf="mostrarLocalizacao(aparelho.EstoqueId)">
            <ion-col size="4">Localização:</ion-col>
            <ion-col size="8">{{aparelho?.Localizacao}}</ion-col>
          </ion-row>

          <div *ngIf="aparelho.Etiquetas.length > 1">
  <!--           <div *ngIf="aparelho.OrigemTabela==2">
              <ion-row *ngFor="let e of aparelho.Etiquetas | sortBy:'desc':'Atribuicao'; first as isFirst">
                <ion-col size="4"><span *ngIf="isFirst" class="etiquetas" >Etiquetas:</span></ion-col>
                <ion-col size="8">{{e.Codigo ? e.Codigo : e}}</ion-col>
              </ion-row>  
            </div>
            <div *ngIf="aparelho.OrigemTabela==1">
   -->            
              <ion-row *ngFor="let e of aparelho.Etiquetas | sortBy:'desc'; first as isFirst">
                <ion-col size="4"><span *ngIf="isFirst" class="etiquetas" >Etiquetas:</span></ion-col>
                <ion-col size="8">{{e.Codigo ? e.Codigo : e}}</ion-col>
              </ion-row>  
            <!-- </div> -->
          </div>
        </ion-label>
      </ion-item>
  
      <ion-item button color="primary" class="ion-margin-bottom"
          (click)="onAparelhoSelecionadoClick(aparelho)">
        <ion-label>Selecionar este aparelho</ion-label>
        <ion-icon slot="start" name="checkmark-outline"></ion-icon>
      </ion-item>
    </div>
  </ion-list>
  
