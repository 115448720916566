import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { throwIfEmpty } from 'rxjs/operators';

@Component({
  selector: 'util-util-aparelho-lista',
  templateUrl: './util-aparelho-lista.component.html',
  styleUrls: ['./util-aparelho-lista.component.css']
})
export class UtilAparelhoListaComponent implements OnInit {

  @Input() aparelhos: any[];
  @Input() public origem: string;
  @Input() public estoqueOrigemId: number;
  @Output() aparelhoSelecionado = new EventEmitter();
  @Output() limparLista = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  onAparelhoSelecionadoClick(aparelho) {
    this.aparelhoSelecionado.emit(aparelho);
  }

  onLimparClick() {
      this.limparLista.emit();
  }

  mostrarEstoque(estoqueId) {

    if (!this.origem)
      return false;
    if (estoqueId)
      return true;
  }

  mostrarLocalizacao(estoqueId) {

    if (!estoqueId)
      return false;
    return (this.origem == "App-Estoque" && estoqueId == +this.estoqueOrigemId);
  }
}
